<template>
  <div class="operation-box" ref='page'>
    <div class="operation-type-box">
      <!-- <p>选择模版：</p> -->
      <a-radio-group v-model="currentTemp.type" button-style="solid" size="small">
        <a-radio-button :value="item.type" v-for="item in templateList" :key="item.type">{{item.name}}</a-radio-button>
      </a-radio-group>
    </div>
    <div class="upload-box">
      <div class="upload-head">
        <p class="upload-head-title">添加商品：</p>
        <a-radio-group @change="changeGoodsType" :value='currentTemp.goodsType'>
          <a-radio value="single">商品</a-radio>
          <a-radio value="group">商品分组</a-radio>
        </a-radio-group>
      </div>

      <draggable v-if="currentTemp.goodsType==='single'" @change="changePosition" :list="goodsObj.goodsList" class="goods-box" group="goods-list" draggable=".goods-item-box">
        <div class="goods-item-box" v-for="(item,index) in goodsObj.goodsList" :key="index">
          <a-icon type="close-circle" class="delete-item" @click="deleteItem(index)" />
          <div v-if='item.productId.indexOf("xfxproductid") !==-1' class='demo-tag'>模版商品</div>
          <img :src="baseUrl + item.mainPicPath" alt class="goods-img" />
        </div>
        <div class="goods-item-box add" @click="addGoods">
          <a-icon type="plus" />
        </div>
      </draggable>
      <div class='tips' v-if="currentTemp.goodsType==='single' && goodsObj.goodsList.length && goodsObj.goodsList.findIndex(x=>x.productId.indexOf('xfxproductid') !== -1) !==-1">注：模版数据！请自行替换商品</div>
      <div>

        <div class="upload-head" style='margin-top: 24px;' v-if="currentTemp.goodsType==='group'">
          <p class="upload-head-title">选择商品分组：</p>
          <a-select placeholder='请选择商品分组' v-model='currentTemp.groupId' @change='selectGroup' style="width: 180px;">
            <a-select-option :value="item.productGroupId" :key='item.productGroupId' v-for='item in groupList'>{{item.groupName}}</a-select-option>
          </a-select>
          <a-button type='link' @click='$router.push("/shop/tags")'>添加分组</a-button>
        </div>
        <div class='tips' v-if="currentTemp.goodsType==='group' && currentTemp.groupId === '商品分组模版'">注：模版数据！请自行选择分组</div>
      </div>
    </div>
    <div class="goods-style-box" v-if="goodsObj.goodsList.length !==0">
      <div class='goods-style-item'>
        <div class='label'>模块背景色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="currentTemp.boxBgColor" @input="e=>$set(props, 'boxBgColor', `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`)"></Sketch>
          </template>
          <div class='bg-btn' :style='{background: currentTemp.boxBgColor}'></div>
        </a-popover>
      </div>
      <div class="goods-style-item">
        <div class="label">商品圆角：</div>
        <a-switch v-model="currentTemp.isRound" checked-children="开" un-checked-children="关" />
      </div>
      <div class="goods-style-item">
        <div class="label">模块上下间距：</div>
        <a-slider :default-value="0" :min="0" :max="50" :step="1" v-model="currentTemp.boxPaddingH" tooltipPlacement="right" style="width:180px;margin:0 12px;" />
        <span>{{currentTemp.boxPaddingH}}px</span>
      </div>
      <div class="goods-style-item">
        <div class="label">模块左右边距：</div>
        <a-slider :default-value="0" :min="0" :max="50" :step="1" v-model="currentTemp.boxPaddingV" tooltipPlacement="right" style="width:180px;margin:0 12px;" />
        <span>{{currentTemp.boxPaddingV}}px</span>
      </div>
      <div class="goods-style-item">
        <div class="label">商品间距：</div>
        <a-slider :default-value="0" :min="0" :max="50" :step="1" v-model="currentTemp.itemMargin" tooltipPlacement="right" style="width:180px;margin:0 12px;" />
        <span>{{currentTemp.itemMargin}}px</span>
      </div>
      <div class="goods-style-item" v-if='currentTemp.type !==3'>
        <div class="label">图片比例：</div>
        <a-radio-group v-model="currentTemp.imgScale">
          <a-radio-button :value="1">1:1</a-radio-button>
          <a-radio-button :value="0.6667">3:2</a-radio-button>
          <a-radio-button :value="1.3333">3:4</a-radio-button>
          <a-radio-button :value="0.5625">16:9</a-radio-button>
        </a-radio-group>
      </div>
      <div class="goods-style-item">
        <div class="label">显示副标题：</div>
        <a-checkbox v-model="currentTemp.showSubTitle">
        </a-checkbox>
      </div>
      <div class="goods-style-item">
        <div class="label">显示折标价：</div>
        <a-checkbox v-model="currentTemp.showPoint">
        </a-checkbox>
      </div>
      <div class="goods-style-item">
        <div class="label">显示划线价：</div>
        <a-checkbox v-model="currentTemp.showOriginalPrice">
        </a-checkbox>
      </div>
      <div class="goods-style-item">
        <div class="label">显示销量：</div>
        <a-checkbox v-model="currentTemp.showSales">
        </a-checkbox>
      </div>
      <div class="goods-style-item">
        <div class="label">显示库存：</div>
        <a-checkbox v-model="currentTemp.showStock">
        </a-checkbox>
      </div>
      <div class="goods-style-item">
        <div class="label">显示购买按钮：</div>
        <a-checkbox v-model="currentTemp.showBuyBtn">
        </a-checkbox>
      </div>
    </div>
    <div v-if='goodsObj.goodsList.length ===0 && currentTemp.goodsType==="group" && currentTemp.groupId'>
      <a-empty description='该分组下没有任何商品'></a-empty>
    </div>
    <a-modal v-model="selectGoodsModal" title="选择商品" :maskClosable="false" @cancel="selectCancel" @ok="selectedDone" :getContainer="getContainer">
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="flex:1;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="getTableList">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table :pagination="pagination" :columns="columns" :dataSource="allGoods" :rowKey="record => record.productId" :loading="selectGoodsLoading" :row-selection="{ selectedRowKeys: goodIds, onChange: onSelectChange}" @change="handleTableChange">
        <template slot="name" slot-scope="text,record">
          <div class="table-goods-info">
            <base-img class="goods-img" :src="record.productImg" width="60" height="60" />
            <a-button type="link" class="goods-name" @click="goGoodsDetail(record.productId)">{{record.name}}</a-button>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, watch, ref } from '@vue/composition-api'
import { common, goods, shop } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import draggable from 'vuedraggable'
import { Sketch } from 'vue-color'
export default {
  components: {
    draggable,
    Sketch
  },
  props: {
    props: {
      type: Object
    },
    goodsObject: {
      type: Object
    }
  },
  setup (props, { root, emit }) {
    const columns = [
      {
        title: '商品',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' }
      }
    ]
    const templateList = [
      {
        name: '大图模式',
        type: 0
      },
      {
        name: '一行一个',
        type: 3
      },
      {
        name: '一行两个',
        type: 1
      },
      {
        name: '一行三个',
        type: 2
      },
      {
        name: '横向滚动',
        type: 4
      }
    ]
    const baseApi = process.env.VUE_APP_API
    const baseUrl = process.env.VUE_APP_IMG
    const state = reactive({
      productName: '',
      groupList: [],
      goodIds: [], // 弹框选中的id
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small'
      },
      selectGoodsModal: false,
      selectGoodsLoading: false,
      allGoods: [] // 商品列表
    })
    const currentTemp = ref({})
    const goodsObj = ref({
      goodsList: []
    })
    watch(
      () => props.goodsObject.seqNum,
      (data) => {
        init()
      }
    )
    onMounted(async () => {
      selectGoodsType()
      init()
    })
    function init () {
      state.productName = ''
      state.selectGoodsModal = false
      state.selectGoodsLoading = false

      currentTemp.value = props.props
      goodsObj.value = props.goodsObject
      state.goodIds = props.props.goodIds
    }
    async function addGoods () {
      state.selectGoodsModal = true
      state.pagination.current = 1
      if (state.allGoods.length === 0) {
        getTableList()
      }
    }
    function resetSearch () {
      state.productName = ''
      getTableList()
    }

    async function getTableList () {
      state.selectGoodsLoading = true
      let { code, msg, data, page } = await goods.getGoodsList({
        state: 1,
        name: state.productName,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.selectGoodsLoading = false
      if (code === '00000') {
        state.allGoods = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function onSelectChange (selectedRowKeys, selectedRows) {
      state.goodIds = selectedRowKeys.reverse()
    }
    function handleTableChange (page) {
      state.pagination = page
      getTableList()
    }
    function selectedDone () {
      state.selectGoodsModal = false
      currentTemp.value.goodIds = state.goodIds.reverse().filter(x => x.indexOf('xfxproductid') === -1)
      getProductByIds()
    }
    function selectCancel () {
      state.goodIds = currentTemp.value.goodIds
      getProductByIds()
    }
    function goGoodsDetail (id) {
      root.$router.push(`/goods/goods_detail/${id}`)
    }
    function deleteItem (index) {
      let id = goodsObj.value.goodsList[index].productId
      let _index = currentTemp.value.goodIds.indexOf(id)
      currentTemp.value.goodIds.splice(_index, 1)
      goodsObj.value.goodsList.splice(index, 1)
    }
    async function selectGoodsType () {
      let { code, msg, data } = await common.getProductGroupByShopId({
        shopId: getSession(SHOPID)
      })
      if (code === '00000') {
        state.groupList = data
      } else {
        root.$message.error(msg || '获取分组数据失败')
      }
    }
    function changePosition (e) {
      currentTemp.value.goodIds = goodsObj.value.goodsList.map(item => {
        return item.productId
      })
    }
    async function selectGroup (e) {
      let { code, data, msg } = await shop.getProductByGroup({
        shopId: getSession(SHOPID),
        productGroupId:
          state.groupList.find((item) => item.productGroupId === e).seqNum === -1 ? '' : e
      })
      if (code === '00000') {
        goodsObj.value.goodsList = data
        goodsObj.value.groupImg =
          state.groupList.find((item) => item.productGroupId === e).groupImg || ''
      } else {
        root.$message.error(msg || '获取分组下商品数据失败')
      }
    }
    async function changeGoodsType (e) {
      currentTemp.value.goodsType = e.target.value
      if (e.target.value === 'single') {
        if (currentTemp.value.goodIds) {
          getProductByIds()
        } else {
          goodsObj.value.goodsList = []
        }
      } else {
        if (currentTemp.value.groupId) {
          selectGroup(currentTemp.value.groupId)
        } else {
          goodsObj.value.goodsList = []
        }
      }
    }
    async function getProductByIds () {
      const { data, code, msg } = await goods.getProductByIds({
        productId: state.goodIds.join(',')
      })
      if (code === '00000') {
        goodsObj.value.goodsList = data
      } else {
        goodsObj.value.goodsList = []
        root.$message.error(msg || '获取数据失败')
      }
    }
    function getContainer () {
      return document.getElementById('page-box')
    }

    return {
      changePosition,
      columns,
      goodsObj,
      baseApi,
      baseUrl,
      ...toRefs(state),
      currentTemp,
      templateList,
      common,
      addGoods,
      onSelectChange,
      handleTableChange,
      getTableList,
      selectedDone,
      selectCancel,
      goGoodsDetail,
      deleteItem,
      selectGoodsType,
      selectGroup,
      resetSearch,
      changeGoodsType,
      getContainer,
      init
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .ant-modal-body {
  padding: 0;
}
.operation-box {
  background: #fff;
  .operation-type-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    padding: 14px 14px 0;
  }
  .upload-box {
    padding: 24px 0;
    .upload-head {
      padding: 0 14px;
      display: flex;
      font-size: 14px;
      color: #666;
      align-items: center;
      .upload-head-title {
        font-size: 14px;
        flex-shrink: 0;
      }
      .upload-head-desc {
        font-size: 12px;
        color: #666;
        padding-bottom: 8px;
      }
    }
    .goods-box {
      margin-top: 12px;
      padding: 0 14px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;

      .goods-item-box:nth-of-type(4n) {
        margin-right: 0;
      }
      .goods-item-box.add {
        cursor: pointer;
        font-size: 24px;
        color: #666;
      }
      .goods-item-box.add:hover {
        border-color: #1890ff;
        color: #1890ff;
      }
      .goods-item-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        height: 84px;
        width: 84px;
        padding: 2px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px dashed #666;
        margin: 0 12px 12px 0;
        cursor: pointer;
        &:hover .delete-item {
          display: block;
        }
        .goods-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .delete-item {
          display: none;
          position: absolute;
          top: -6px;
          right: -8px;
          font-size: 20px;
          background: #fff;
          border-radius: 50%;
          z-index: 1;
          color: #666;
        }
        .uploader-card {
          width: 72px;
          height: 72px;
          position: relative;
          margin-right: 8px;
        }
      }
    }
  }
  .goods-style-box {
    background: #fff;
    padding: 0px 14px 200px;
    .goods-style-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .label {
        width: 8em;
        text-align: right;
      }
      ::v-deep .ant-slider-handle {
        border-color: #189ff0;
      }
      ::v-deep .ant-slider-track {
        background-color: #189ff0;
      }
      ::v-deep .ant-slider-rail {
        background-color: #c1c1c1;
      }
    }
  }
}
.table-goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    width: 60px;
  }
  .goods-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
::v-deep .ant-table-tbody > tr > td {
  padding: 4px 16px;
}
.search-box {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  justify-content: flex-end;
}
::v-deep .ant-form-item {
  margin-bottom: 12px;
}
.demo-tag {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  background: linear-gradient(to right, red, orange);
  color: #fff;
  padding: 0 8px;
  width: calc(100% - 2px);
  text-align: center;
  margin: 1px;
}
.tips {
  padding: 8px 16px;
  color: red;
  font-size: 12px;
}
.bg-btn {
  width: 32px;
  height: 24px;
  border: 1px solid #ddd;
  cursor: pointer;
}
</style>
